:root {
  --black: #000;
  --yellow: #FEFF3A;
}

@font-face {
  font-family: SportingGrotesque;
  src: url("./font/SportingGrotesque-Regular.otf") format("opentype");
}

@font-face {
  font-family: SportingGrotesque;
  font-weight: bold;
  src: url("./font/SportingGrotesque-Bold.otf") format("opentype");
}

html, 
body, 
#root {
  min-height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;

  color: var(--black);
}

#landing , 
.article {
  position: relative;
  min-height: 100vh;
}

body {
  font-family: 'SportingGrotesque';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--yellow);
}

h1 {
  line-height: 1.25em;
  text-align: center;
  margin-bottom: 0;
  color: var(--black);
}

a {
  color: var(--black);
}

p {
  color: var(--black);
}

button {
  font-family: 'SportingGrotesque';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

.content-wrapper {
  max-width: min(100%, 720px);
  margin: 0 auto;
  padding-bottom: 50px; /*Footer Height + 20px bottom spacing*/
}

.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 200px;
}

.center-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#content-404 {
  text-align: center;

  margin-top: 100px;
  padding-bottom: 50px;
}

#content-404 #gif {
  pointer-events: none;
}

#content-404 a {
  margin: 0 auto;
}

/* ===== NAVIGATION ===== */
#navigation {
  position: relative;
  height: 116px;
  padding: 0;
}

#navigation ul {
  list-style: none; 
  height: 30px;
  margin: 0;
  position: absolute;
  right: 20px;
  bottom: 30px;
}

#navigation li {
  display: inline;
  padding: 0 20px;
  float: right;
}

#back-button {
  position: absolute;
  bottom: 30px;
  left: 20px;
  width: 98px;
}

.full-page-wrapper {
  padding-bottom: 100px; /*Footer Height + 20px bottom spacing + 50px padding*/
}

/* ===== LANDING ===== */

#landing h1 {
  font-size: 2.5em;
  margin-top: 0;
}

.background-penis {
  position: fixed;
  height: 100%;
  width: auto;
  z-index: -1000;
}

.phonetics {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-top: 40px;
}

.definition-container {
  margin: 30px 0;
  display: table;
  text-align: center;
  min-height: 270px;
}

.definition {
  font-weight: bold;
  font-size: 4em;
  line-height: 88px;
  display: table-cell;
  vertical-align: middle;
}

.action-button {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.action-button-with-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.transparent-button {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  height: 50px;
}

.transparent-button:hover {
  background-color: rgba(0, 0, 0, .1);
}

.transparent-button:active {
  background-color: rgba(0, 0, 0, .3);
}

/* ===== ORIGINS ===== */

.article h1 {
  font-size: 5.5em;
}

/* ===== FOOTER ===== */

footer {
  position: absolute;
  bottom: 0;
  height: 30px;
  padding: 50px 0 30px 20px;
}

.pixelated-penis-with-spinning-text {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 255px;
  width: 255px;
}

.spinning-text {
  animation-duration: 30s;
  animation-name: spintext;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.pixelated-penis {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spintext {
  from {
    transform: rotate(0deg);
    position: absolute;
  }

  to {
    transform: rotate(360deg);
    position: absolute;
  }
}

#creators {
  margin: 0;
}

.transparent-button span {
  padding-top: 5px;
  font-weight: bold;
}

/* ===== Share Sheet ===== */
#share-sheet-container {
  border: 3px solid var(--black);
  width: 720px;
  max-width: 90%;
  height: 250px;
  background-color: var(--yellow);
  text-align: center;
  z-index: 1000;
  box-shadow: 5px 10px rgba(0, 0, 0, .5);
}

.share-sheet-title {
  position: relative;
  top: 64px;
  font-weight: bold;
}

.share-sheet-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title-sheet-action-buttons {
  position: relative;
  top: 130px;
}

#share-sheet-container .action-button-with-text span {
  font-size: 1.9em;
  text-decoration: underline;
}

/* ===== 1024 ===== */

@media (max-width:1024px) {
  .box {
    padding: 0 50px;
  }
}

/* ===== 576px ===== */

@media (max-width:576px) {
  .background-penis {
    height: auto;
    width: 100%;
  }

  #navigation {
    height: 80px;
  }

  #back-button {
    bottom: 0;
  }
  
  #navigation ul {
    list-style: none; 
    height: 30px;
    margin: 0;
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
  
  #navigation li {
    display: inline;
    padding: 0 20px;
    float: right;
  }

  .box {
    padding: 0 20px;
  }

  #landing h1 {
    font-size: 1.5em;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .full-page-wrapper {
    padding-bottom: 0;
  }

  .article h1 {
    font-size: 3.2em;
    text-align: left;
  }

  .content-wrapper {
    max-width: min(100%, 720px);
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
  }

  .definition-container {
    min-height: 230px;
  }

  .definition {
    font-size: 2.3em;
    line-height: 1.2em;
    width: -moz-fit-content;
    width: fit-content;
  }

  .phonetics {
    padding: 20px 0;
    font-size: 0.8em;
  }

  .pixelated-penis-with-spinning-text {
    position: relative;
    bottom: unset;
    right: unset;
    height: 255px;
    width: 255px;
  }

  #share-sheet-container {
    height: 320px;
  }

  .action-buttons-container {
    padding: 20px 0 50px 0;
    flex-direction: column;
    row-gap: 15px;
  }

  .title-sheet-action-buttons {
    top: 60px;
  }
  
  /* ===== FOOTER =====  */
  footer {
    padding: 20px;
  }

  .pixelated-penis-with-spinning-text {
    margin: 0 auto;
    float: unset;
  }

  .spinning-text-group {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .spinning-text {
     position: relative;
     margin: 0 auto;
  }

  .pixelated-penis {
    width: auto;
    height: auto;
    text-align: center;
    position: relative;
    bottom: unset;
    right: unset;
  }
}

/* ===== 375px ===== */

@media (max-width: 375px) {
  #landing h1 {
    font-size: 1.3em;
  }

  .article h1 {
    font-size: 3em;
    text-align: left;
  }

  .definition-container {
    margin: 0;
    padding-bottom: 0;
  }

  .definition {
    font-size: 2em;
  }
}

/* ===== 320px ===== */

@media (max-width: 375px) {
  #landing h1 {
    font-size: 1em;
  }

  .article h1 {
    font-size: 2.em;
    text-align: left;
  }

  .definition-container {
    min-height: 140px;
  }

  .definition {
    font-size: 1.5em;
  }
}